<div *ngIf="environment.mobile" class="container container_app" style="padding-top: 0px" [class.container_app_aop]="environment.domain == 'ophthalmology'">
  <div class="color-mask" *ngIf="environment.domain == 'aesthetics'"></div>
  <div class="bg-video" *ngIf="environment.domain == 'aesthetics'">
    <video poster="assets/img/bg_image.png" playsinline autoplay muted loop>
      <source src="assets/video/veronique.mp4" type="video/mp4" />
      <img src="assets/img/bg_image.png" alt="Photo arrière plan" />
    </video>
  </div>
  <div class="bg-gif" style="background-image:url('assets/img/bg_image_aop.png')" *ngIf="environment.domain == 'ophthalmology'"></div>
  <div class="home-text">
    <img class="logo_app" src="assets/img/logo_white.png" alt="Logo IMCAS Academy" *ngIf="environment.domain == 'aesthetics'">
    <img class="logo_app" src="assets/img/logo_white_aop.png" alt="Logo AOP Academy" *ngIf="environment.domain == 'ophthalmology'">
    <h1>{{'cart.Welcome_to_imcas_academy'|translate}}</h1>
    <h2>{{'application.Logout_screen_subtitle'|translate}}</h2>
  </div>
  <div class="buttons-group">
    <a [routerLink]="'/account/login'" class="transparent-button primary">{{'application.Logout_screen_cta'|translate}}</a>
    <a [routerLink]="'/account/create'" class="transparent-button">{{'account.Dont_have_an_account'|translate}} {{'account.Sign_up_now'|translate}}</a>
  </div>
</div>
<ng-template #nextCongress let-c="close" let-d="dismiss">
 <div class="modal-body d-flex flex-column" style="gap:2rem">
   <div>
     <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
       <i class="fa fa-times" aria-hidden="true"></i>
     </button>
   </div>
   <h2 class="text-center mt-2 mb-0">The largest congress in medical aesthetics is back: </h2>
   <img src="{{ environment.cdn_base_url }}/assets-front/img/iwc2025_academy_modal.jpg">
   <a [href]="environment.base_url + '/' + locale + '/attend/imcas-world-congress-2025'" class="transparent-button" style="background-color: #001148">{{'layout.More_info' | translate }}</a>
 </div>
</ng-template>
<ng-template #aopClosing let-c="close" let-d="dismiss">
  <div class="modal-body d-flex flex-column" style="gap:2rem">
    <div>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <h2 class="text-center mt-2 mb-0">Le congrès des AOP revient en janvier 2025.</h2>
    <a href="https://www.aopcongress.com/" class="transparent-button" target="_blank" style="background-color: #19487c">Plus d'informations</a>
  </div>
</ng-template>

<div *ngIf="!environment.mobile"  class="page home">
  <app-scroll-top></app-scroll-top>
  <section class="section section-filtered home-top"
           [ngClass]="isAop ? 'section-filtered-aop' : 'section-filtered-imcas'">
    <div class="content">
      <h1>{{realHeading}}
          <span *ngIf="realHeading2">{{realHeading2}}</span>
      </h1>
      <p *ngIf="isAop" class="home-top-intro title_bicolor"
         [innerHTML]="(totalUser === 0 ? 'academy.Join_growing_com' : 'academy.Join_growing_community_aop')|translate:{nb: totalUser}"></p>
      <p *ngIf="!isAop" class="home-top-intro title_bicolor"
         [innerHTML]="(totalUser === 0 ? 'academy.Join_growing_com' : 'academy.Join_growing_community')|translate:{nb: totalUser}"></p>
      <div class="centered-buttons">
        <a routerLink="/../account/create" class="button button-orange button-large">
          {{'home.Sign_up_for_free'|translate}}
        </a>
        <a *ngIf="environment.domain === 'aesthetics'" routerLink="/premium" class="button button-large " [ngClass]="isAop ? 'button-red' : 'button-blue'">
          {{'premium.Go_premium'|translate}}
        </a>
      </div>

    </div>
  </section>

  <section class="section features" [style.background]="isAop ? '#19487c' : null">
    <h2 class="title_bicolor" [innerHTML]="'academy.Website_name_features'|translate: {websiteName: isAop ? 'AOP' : 'IMCAS Academy'}"></h2>
    <div class="features-container content">
      <ul>
        <li>
          <span class="icon icon-track-large"></span>
          <div>
            <h3 class="title_bicolor" [innerHTML]="'academy.Track_progress'|translate"></h3>
            <p>{{'academy.Track_your_progress_details'|translate}}</p>
          </div>
        </li>
        <li>
          <span class="icon icon-connect-large"></span>
          <div>
            <h3 class="title_bicolor" [innerHTML]="'academy.Connect_and_exchange'|translate"></h3>
            <p>{{'academy.Have_help_experts'|translate}}</p>
          </div>
        </li>
       <li *ngIf="environment.domain === 'aesthetics'">
          <div class="icon icon-video-large"></div>
          <div>
            <h3 class="title_bicolor" [innerHTML]="'academy.Monthly_free_webinar'|translate"></h3>
            <p>{{'webinar.Home_descr'|translate}}</p>
            <p style="margin-bottom:0">{{'webinar.Join_next_live_webinar'|translate}}
              <strong>{{ getNextWebinarDate()}}</strong>
            </p>
            <a [routerLink]="'/webinar'" class="register_now {{isAop ? 'isAop' : ''}}">{{ 'account.Register_now'|translate }}!</a>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <app-magazine-section *ngIf="magazine" [magazine]="magazine"></app-magazine-section>
  <section class="section" *ngIf="environment.domain == 'aesthetics'">
    <div class="content">
      <h2 class="into">{{ 'academy.Our_scientific_partners'|translate }}</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="home-partners d-none d-md-block" *ngIf="partnersWithoutMag.length !== 0 && !mobile">
            <div class="marquee-partners">
              <app-slideshow [elements]="partnersWithoutMag"></app-slideshow>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="download-app" class="section dashboard-mobile-app" *ngIf="environment.domain == 'aesthetics'" [style.background]="isAop ? '#19487c' : null">
    <div class="content">
      <div class="row">
        <div class="col-md-7 text-right desktop-only">
          <img src="{{ environment.cdn_base_url }}/assets-front/img/app_mockeup_iphone.png" class="mockup"
               alt="mockup-iphone">
        </div>
        <div class="col-md-5 download-buttons">
          <h2 class="title_bicolor" [innerHTML]="'academy.Download_the_app'|translate"></h2>
          <p>{{'academy.Download_app_details'|translate}}</p>
          <div class="buttons">
            <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8" target="_blank"
               *ngIf="getMobileOperatingSystem() == 'iOS' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png"
                   alt="download app on app store">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.imcas.academy" target="_blank"
               *ngIf="getMobileOperatingSystem() == 'Android' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png"
                   alt="download app on google play">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section home-videos home-section">
    <div class="content d-md-block">
      <div class="title-videos">
        <h2>{{'academy.Enroll_courses_watch_videos'|translate}}</h2>
        <p [innerHTML]="'academy.Learn_with'|translate:{nbLectures: mediasHits, nb: coursesHits}"></p>
      </div>
        <app-jwplayer-video *ngIf="video"
                            [file]="video.cdn_file_short"
                            [file2]="video.cdn_file_short"
                            [media]="video"
                            [width]="'100%'"
                            [aspectratio]="'16:9'"
                            [image]="isAop ? environment.base_url + '/img/aop/home/video_fr.png' : environment.base_url + '/img/academy/home/video.png'">
        </app-jwplayer-video>
      <p class="signup">{{'academy.Sign_up_for_free'|translate}}</p>
      <div class="buttons centered-buttons">
        <a routerLink="/../account/create" class="button button-orange button-large">
          {{'home.Sign_up_for_free'|translate}}
        </a>
        <a *ngIf="environment.domain === 'aesthetics'" routerLink="/premium" class="button button-large " [ngClass]="isAop ? 'button-red' : 'button-blue'">
          {{'premium.Go_premium'|translate}}
        </a>
      </div>
    </div>
  </section>

  <section class="home-top-discover content">
    <div class="title-top">
      <p>{{isAop ? ('home.Discover_AOP_academy_before'|translate) : ('home.Discover_academy_before'|translate) }}</p>
      <a [routerLink]="'/library/lectures'" class="button button-rounded button-orange">
        {{'home.Full_library'|translate}}
      </a>
    </div>

    <div class="search">
      <app-search-ui
        [transparent]="true"
        [usersIndex]="true"
        [productsIndex]="true"
        [topicsIndex]="true"
        [companiesIndex]="true"
        [pagesIndex]="true"
        [congressesIndex]="true"
        [coursesIndex]="true"
        [mediasIndex]="true"
        [articlesIndex]="true"
      ></app-search-ui>
    </div>


    <div class="home-top-quicklinks">
        <div class="themes">
          <a *ngFor="let theme of themes"
             [routerLink]="'/library/lectures/theme/' + theme.id + '/' + getSlug(translationService.getTranslatedName(theme.translations, 'en'))"
             class="button button-rounded">
            {{ translationService.getTranslatedName(theme.translations, locale).length > 30 ?
            translationService.getTranslatedName(theme.translations, locale).substring(0,
              translationService.getTranslatedName(theme.translations, locale).substring(30).search("\n")+30
            ).trim() + "..."
            :
            translationService.getTranslatedName(theme.translations, locale)
            }}
          </a>

        </div>
    </div>
  </section>

  <section class="section feed" *ngIf="!isAop">
    <div class="feedbacks content">
      <app-feedback *ngFor="let feedback of feedbacks" [feedback]="feedback" [home]="true"></app-feedback>
    </div>
  </section>
</div>
