import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LocaleService} from '../../../services/locale.service';
import {TranslationService} from '../../../services/translation.service';
import * as moment from 'moment';
import {Theme} from '../../../models/theme.model';
import {Magazine} from '../../../models/magazine.model';
import {Feedback} from '../../../models/feedback.model';
import {Webinar} from '../../../models/webinar.model';
import {Partnership} from '../../../models/partnership.model';
import {environment} from '../../../../environments/environment';
import {UserService} from 'app/services/user.service';
import {ThemeService} from 'app/services/theme.service';
import {MagazineService} from 'app/services/magazine.service';
import {CourseService} from 'app/services/course.service';
import {WebinarService} from 'app/services/webinar.service';
import {FeedbackService} from 'app/services/feedback.service';
import {CongressService} from 'app/services/congress.service';
import {MediaService} from 'app/services/media.service';
import {Media} from '../../../models/media.model';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {Router} from "@angular/router";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'home-component',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        ThemeService,
        UserService,
        MagazineService,
        MediaService,
        CongressService,
        FeedbackService,
        WebinarService,
        CourseService,
    ]
})

export class HomeComponent <T> implements OnInit, AfterViewInit {
    users: any;
    themes: Theme[];
    col1: any;
    col2: any;
    locale: any;
    magazine: Magazine;
    lectures: any;
    media$: Observable<Media>;
    video: Media;
    courses: any;
    feedbacksIds: string = '712,716,746,744,708';
    feedbacks: Feedback[] = [];
    nextWebinar: Webinar;
    mobile: boolean;
    partners: Partnership[];
    partnersWithoutMag: Partnership[] = [];
    heading = environment.domain === 'aesthetics' ? 'academy.Main_subtitle_academy' : 'page.Landing_head1';
    realHeading = environment.domain === 'aesthetics' ? 'academy.Main_subtitle_academy_1' : 'page.Landing_head1';
    realHeading2 = environment.domain === 'aesthetics' ? 'academy.Main_subtitle_academy_2' : null;
    subHeading = environment.domain === 'aesthetics' ? 'academy.Main_subtitle_academy' : 'academy.Main_subtitle_academy_aop';
    isAop: boolean = environment.domain === 'ophthalmology';
    environment: any = environment;
    totalUser: any;
    mediasHits: number;
    coursesHits: number;

    dataReceivedFromWeb = [];
    @ViewChild('nextCongress', {static: false}) nextCongress: ElementRef;
    @ViewChild('aopClosing', {static: false}) aopClosing: ElementRef;

    constructor(private localeService: LocaleService,
                private userService: UserService,
                private themeService: ThemeService,
                private magazineService: MagazineService,
                private mediaService: MediaService,
                private modalService:NgbModal,
                private congressService: CongressService,
                private translate: TranslateService,
                private feedbackService: FeedbackService,
                private webinarService: WebinarService,
                private courseService: CourseService,
                public translationService: TranslationService,
                private router: Router,
                protected localStorage: LocalStorage) {
        this.col1 = [];
        this.col2 = [];
    }

    ngOnInit() {

      if (environment.domain === 'aesthetics') {
            this.media$ = this.mediaService.getMedia(18306, {
                media_lecture: 0,
                media_speakers: 0
            });
        }
        this.translate.get(environment.domain === 'aesthetics' ? 'academy.Main_subtitle_academy_1' : 'page.Landing_head1')
            .subscribe((res: string) => {
                this.realHeading = res;
            });

        if (this.realHeading2) {
          this.translate.get(this.realHeading2).subscribe((res: string) => { this.realHeading2 = res})
        }
        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.mobile = w < 767;
        this.locale = this.localeService.currentLocale();

        if (!localStorage.getItem('tutorialSeen') && environment.mobile) {
            this.router.navigateByUrl('/tutorial');
        }

        this.localStorage.getItem<any>('HOME-users_' + environment.domain).subscribe((users: any) => {
            if (!('users' in this.dataReceivedFromWeb) && users) {
                this.setRoundedNbUsers(users.total);
            }
        });
        this.userService.getUsersCount().subscribe(
            count => {
                this.setRoundedNbUsers(count.total);
                this.dataReceivedFromWeb['users'] = true;
                this.localStorage.setItem('HOME-users_' + environment.domain, count).subscribe(() => {
                });
            });

        this.localStorage.getItem<any>('HOME-themes_' + environment.domain).subscribe((themes: Theme[]) => {
            if (!('themes' in this.dataReceivedFromWeb) && themes) {
                this.setDataGetMainThemes(themes);
            }
        });
        this.themeService.getMainThemes('sorting_order').subscribe(
            themes => {
                this.setDataGetMainThemes(themes);
                this.dataReceivedFromWeb['themes'] = true;
                this.localStorage.setItem('HOME-themes_' + environment.domain, themes).subscribe(() => {
                });
            });

        this.localStorage.getItem<any>('HOME-magazine_' + environment.domain).subscribe((magazine) => {
            if (!('magazine' in this.dataReceivedFromWeb) && magazine) {
                this.setDataGetLastMagazine(magazine);
            }
        });
        this.magazineService.getLastMagazine().subscribe(
            magazine => {
                this.setDataGetLastMagazine(magazine);
                this.dataReceivedFromWeb['magazine'] = true;
                this.localStorage.setItem('HOME-magazine_' + environment.domain, magazine).subscribe(() => {
                });
            },
            err => {
            });

        this.localStorage.getItem<any>('HOME-lectures_' + environment.domain).subscribe((lectures) => {
            if (!('lectures' in this.dataReceivedFromWeb) && lectures) {
                this.setDataGetAcademyMedias(lectures);
            }
        });

        this.mediaService.getAcademyMediasCount().subscribe(
            count => {
               this.mediasHits = count.physician;
            });

        this.mediaService.getAcademyMedias().subscribe(
            lectures => {
                this.setDataGetAcademyMedias(lectures);
                this.dataReceivedFromWeb['lectures'] = true;
                this.localStorage.setItem('HOME-lectures_' + environment.domain, lectures).subscribe(() => {
                });
            },
            err => {
            });


        this.localStorage.getItem<any>('HOME-courses_' + environment.domain).subscribe((courses) => {
            if (!('courses' in this.dataReceivedFromWeb) && courses) {
                this.setDataGetCourses(courses);
            }
        });

        this.courseService.getCoursesCount().subscribe(
            count => {
                this.coursesHits = count.totalCourses;
            });

        this.courseService.getCourses().subscribe(
            courses => {
                this.setDataGetCourses(courses);
                this.dataReceivedFromWeb['courses'] = true;
                this.localStorage.setItem('HOME-courses_' + environment.domain, courses).subscribe(() => {
                });
            },
            err => {
            });

        this.localStorage.getItem<any>('HOME-nextWebinar_' + environment.domain).subscribe((nextWebinar) => {
            if (!('nextWebinar' in this.dataReceivedFromWeb) && nextWebinar) {
                this.setDataGetNextWebinar(nextWebinar);
            }
        });
        this.webinarService.getNextWebinar().subscribe(
            nextWebinar => {
                this.setDataGetNextWebinar(nextWebinar);
                this.dataReceivedFromWeb['nextWebinar'] = true;
                this.localStorage.setItem('HOME-nextWebinar_' + environment.domain, nextWebinar).subscribe(() => {
                });
            },
            err => {
            });


        this.localStorage.getItem<any>('HOME-feedbacks_' + environment.domain).subscribe((feedbacks: Feedback[]) => {
            if (!('feedbacks' in this.dataReceivedFromWeb) && feedbacks) {
                this.setDataGetFeedbacks(feedbacks);
            }
        });
        this.feedbackService.getFeedbacks({ids: this.feedbacksIds}).subscribe(
            feedbacks => {
                this.setDataGetFeedbacks(feedbacks);
                this.dataReceivedFromWeb['feedbacks'] = true;
                /*this.localStorage.setItem('HOME-feedbacks_' + environment.domain, feedbacks).subscribe(() => {
                });*/
            },
            err => {
            });

        this.localStorage.getItem<any>('HOME-media_' + environment.domain).subscribe((video) => {
            if (!('media' in this.dataReceivedFromWeb) && video) {
                this.setDataGetMedia(video);
            }
        });
        this.mediaService.getMedia(this.isAop ? 5174 : 2006, {media_cdn_short: 1}).subscribe(video => {
            this.setDataGetMedia(video);
            this.dataReceivedFromWeb['media'] = true;
            this.localStorage.setItem('HOME-media_' + environment.domain, video).subscribe(() => {
            });
        });


        this.localStorage.getItem<any>('HOME-partners_' + environment.domain).subscribe((partners: Partnership[]) => {
            if (!('partners' in this.dataReceivedFromWeb) && partners) {
                this.setDataGetPartnerships(partners);
            }
        });
        this.congressService.getPartnerships().subscribe(
            partners => {
                this.setDataGetPartnerships(partners);
                this.dataReceivedFromWeb['partners'] = true;
                this.localStorage.setItem('HOME-partners_' + environment.domain, partners).subscribe(() => {
                });
            },
            err => {
            });

    }
  // ngAfterViewChecked() {
  //   const orangeElts = document.querySelectorAll<HTMLElement>('.title_bicolor span');
  //   if (orangeElts) {
  //     orangeElts.forEach(elt => {
  //       elt.style.color = '#fd7e14';
  //     })
  //   }
  // }
  ngAfterViewInit() {
      this.modalService.open(environment.domain === 'aesthetics' ? this.nextCongress : this.aopClosing);
  }
  getSlug(string) {
        return this.translationService.getSlug(string);
    }

    setRoundedNbUsers(total) {
      this.totalUser =  Math.round(total / 100) * 100;
    }

    getNbLectures() {
        if (this.lectures) {
            return Math.round(this.lectures.total / 100) * 100;
        }
        return 1500;
    }

    getNbCourses() {
        if (this.courses) {
            return Math.round(Math.floor(this.courses.total / 10)) * 10;
        }
        return 200;
    }

    getNextWebinarDate() {
        let date;
        if (this.nextWebinar) {
            let locale;
            if (this.locale === 'zh') {
                locale = 'zh-cn';
            } else {
                locale = this.locale;
            }
            moment.locale(locale);
            date = this.nextWebinar.datetime_begin;
        } else {
            date = '2017-10-25 17:00';
        }
        return moment(date).format('MMMM Do YYYY, @HH:mm');
    }

    setDataGetMainThemes(themes: Theme[]) {
      if (themes && themes.length > 0)
      {
        this.themes = themes.slice(0, 7);
      }
    }

    setDataGetLastMagazine(magazine) {
        if (magazine && magazine.length > 0) {
            this.magazine = magazine;
        } else {
            this.magazine = undefined;
        }
    }

    setDataGetAcademyMedias(lectures) {
        this.lectures = lectures;
    }

    setDataGetCourses(courses) {
        this.courses = courses;
    }

    setDataGetNextWebinar(nextWebinar) {
        this.nextWebinar = nextWebinar;
    }

    setDataGetFeedbacks(feedbacks: Feedback[]) {
      if (feedbacks && feedbacks.length >0) {
        this.feedbacks =  feedbacks.slice(0, 4);
      }
    }

    setDataGetMedia(video) {
        this.video = video;
    }

    isDesktop() {
        const ua = this.getMobileOperatingSystem();
        if (ua === 'Android') {
            return false;
        } else if (ua === 'iOS') {
            return false;
        }
        return true;
    }

    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor;
        // Windows Phone must come before Android because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }
        if (/android/i.test(userAgent)) {
            return 'Android';
        }
        if (/MSIE|Edge|Trident/.test(userAgent)) {
            return 'IE/Edge';
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        }
        return 'unknown';
    }

    setDataGetPartnerships(partners: Partnership[]) {
        this.partners = partners;
        this.partnersWithoutMag = [];
        if (partners && partners.length > 0) {
          this.partners.forEach((element, index) => {
            if (element.publication && element.publication.category !== 'Magazines' || element.scientific_society) {
              this.partnersWithoutMag.push(element);
            }
          });
        }
    }
}
